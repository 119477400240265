import { computed, Injectable, Signal } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';

export type GlobalStateType = {
  readonly routeChanged: boolean;
  readonly routeChangeStarted: boolean;
  readonly routeChangeFinished: boolean;
  readonly apiRequestSilent: boolean;
  readonly apiRequestCount: number;
  readonly brandsWidgetBottom: number;
};

const initialGlobalState: GlobalStateType = {
  routeChanged: false,
  routeChangeStarted: false,
  routeChangeFinished: false,
  apiRequestSilent: false,
  apiRequestCount: 0,
  brandsWidgetBottom: 0,
};

@Injectable({
  providedIn: 'root',
})
export class GlobalState {
  readonly #globalState = signalState<GlobalStateType>(initialGlobalState);

  incermentApiRequestCount = (): void => {
    patchState(this.#globalState, { apiRequestCount: this.#globalState.apiRequestCount() + 1 });
  };

  decrementApiRequestCount = (): void => {
    patchState(this.#globalState, {
      apiRequestCount: this.#globalState.apiRequestCount() > 0 ? this.#globalState.apiRequestCount() - 1 : 0,
    });
    patchState(this.#globalState, {
      apiRequestSilent: this.#globalState.apiRequestCount() > 0 ? this.#globalState.apiRequestSilent() : false,
    });
  };

  get apiCallState(): Signal<'init' | 'loading'> {
    return computed(() => (this.#globalState.apiRequestCount() > 0 ? 'loading' : 'init'));
  }

  get apiRequestCount(): Signal<number> {
    return this.#globalState.apiRequestCount;
  }

  get apiRequestSilent(): Signal<boolean> {
    return this.#globalState.apiRequestSilent;
  }

  set apiRequestSilent(apiRequestSilent: boolean) {
    patchState(this.#globalState, { apiRequestSilent });
  }

  get routeChanged(): Signal<boolean> {
    return computed(() => this.#globalState.routeChanged() || (this.apiCallState() === 'loading' && !this.apiRequestSilent()));
  }

  set routeChanged(routeChanged: boolean) {
    patchState(this.#globalState, { routeChanged });
  }

  get routeChangeStarted(): Signal<boolean> {
    return this.#globalState.routeChangeStarted;
  }

  set routeChangeStarted(routeChangeStarted: boolean) {
    patchState(this.#globalState, { routeChangeStarted });
  }

  get routeChangeFinished(): Signal<boolean> {
    return this.#globalState.routeChangeFinished;
  }

  set routeChangeFinished(routeChangeFinished: boolean) {
    patchState(this.#globalState, { routeChangeFinished });
  }

  get brandsWidgetBottom(): Signal<number> {
    return this.#globalState.brandsWidgetBottom;
  }

  set brandsWidgetBottom(brandsWidgetBottom: number) {
    patchState(this.#globalState, { brandsWidgetBottom });
  }
}
